<template>
  <span :class="[
            'MaterialDesign-Icon',
            size==undefined? '' : 'MaterialDesign-Icon-' + size]">

      {{icon}}

  </span>
</template>

<script>
export default {
    name: "MaterialDesign-Icon",
    props:{
        icon:{type: String, required: true},
        size:{type: String, default: undefined}
    }
}
</script>

<style>

</style>